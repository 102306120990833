<template>
  <VRequisitesList
    :requisites="requisites"
  />
</template>

<script>
import VRequisitesList from '@/components/profile/VRequisitesList'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()

    const requisites = computed(() => {
      return store.getters['auth/getUser']
    })

    return {
      requisites
    }
  },
  components: {
    VRequisitesList
  }
}
</script>
